const IconFolderEdit = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      className="anticon"
    >
      <path
        d="M10.5 16.665V14.3588L14.6437 10.2338C14.7562 10.1213 14.8812 10.04 15.0187 9.99004C15.1562 9.94004 15.2938 9.91504 15.4313 9.91504C15.5813 9.91504 15.725 9.94329 15.8625 9.99979C16 10.0563 16.125 10.1405 16.2375 10.2525L16.9313 10.9463C17.0313 11.0588 17.1095 11.1838 17.166 11.3213C17.2225 11.4588 17.2505 11.5963 17.25 11.7338C17.2495 11.8713 17.2245 12.012 17.175 12.156C17.1255 12.3 17.0443 12.428 16.9313 12.54L12.8063 16.665H10.5ZM15.4313 12.465L16.125 11.7338L15.4313 11.04L14.7188 11.7525L15.4313 12.465ZM3 15.165C2.5875 15.165 2.2345 15.0183 1.941 14.7248C1.6475 14.4313 1.5005 14.078 1.5 13.665V4.66504C1.5 4.25254 1.647 3.89954 1.941 3.60604C2.235 3.31254 2.588 3.16554 3 3.16504H7.5L9 4.66504H15C15.4125 4.66504 15.7657 4.81204 16.0597 5.10604C16.3538 5.40004 16.5005 5.75304 16.5 6.16504V8.64004C16.325 8.56504 16.1437 8.50879 15.9562 8.47129C15.7687 8.43379 15.5812 8.41504 15.3937 8.41504C15.0437 8.41504 14.7157 8.47754 14.4097 8.60254C14.1037 8.72754 13.8255 8.91504 13.575 9.16504L9 13.74V15.165H3Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default IconFolderEdit
