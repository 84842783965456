import { useTranslation } from 'react-i18next'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, ModalProps } from 'antd'

interface ModalConfirmProps extends ModalProps {
  title: string
  subTitle?: string
}

const ModalConfirm = ({
  onOk,
  onCancel,
  title,
  subTitle,
  cancelText,
  okText,
  ...props
}: ModalConfirmProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      {...props}
      closeIcon={null}
      footer={null}
      width={500}
    >
      <div className="flex flex-col items-center p-4 pb-8">
        <ExclamationCircleOutlined className="text-red-500 text-6xl" />
        <div className="my-8 text-xl">{title}</div>
        {subTitle && <div className="my-8">{subTitle}</div>}
        <div className="flex gap-3 justify-center">
          <Button
            className="w-40"
            onClick={onCancel}
          >
            {cancelText || t('form.no')}
          </Button>
          <Button
            className="w-40"
            type="primary"
            onClick={onOk}
            danger
          >
            {okText || t('form.yes')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirm
