import { STORAGE_KEY } from 'config/constants'

class ApiAuth {
  storageKey: string
  constructor(storageKey: string = STORAGE_KEY.ACCESS_TOKEN) {
    this.storageKey = storageKey
  }

  getToken() {
    return localStorage.getItem(this.storageKey)
  }

  setToken(token: string) {
    localStorage.setItem(this.storageKey, token)
  }

  removeToken() {
    localStorage.removeItem(this.storageKey)
  }
}

export default ApiAuth
