import { DynamicObject } from 'types/app'

class SingleRequestHandler {
  cancels: object
  constructor() {
    this.cancels = {}
  }

  handle(key: string, cancel: DynamicObject) {
    if (typeof (this.cancels as DynamicObject)[key] === 'function') {
      ;(this.cancels as DynamicObject)[key]()
    }

    ;(this.cancels as DynamicObject)[key] = cancel
  }
}

export default SingleRequestHandler
