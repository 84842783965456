import React from 'react'
import IconAdminMenu from 'assets/icons/IconAdminMenu'
import IconDrawing from 'assets/icons/IconDrawing'
import IconEstimate from 'assets/icons/IconEstimate'
import IconFolderEdit from 'assets/icons/IconFolderEdit'
import IconLeading from 'assets/icons/IconLeading'
import IconSetting from 'assets/icons/IconSetting'
import i18n from 'systems/locales/i18n'
import { ROUTE } from './constants'

const { t } = i18n

export const getSideBarMenu = (userRole: string | null | undefined) => {
  return userRole === 'admin'
    ? [
        {
          key: ROUTE.PROJECTS,
          icon: React.createElement(IconFolderEdit),
          label: t('sidebar.menu.project')
        },
        {
          key: ROUTE.TECHNICAL_DRAWING,
          icon: React.createElement(IconDrawing),
          label: t('sidebar.menu.technical_drawings')
        },
        {
          key: ROUTE.PURCHASE_ORDER,
          icon: React.createElement(IconLeading),
          label: t('sidebar.menu.purchase_order')
        },
        {
          key: ROUTE.ESTIMATE,
          icon: React.createElement(IconEstimate),
          label: t('sidebar.menu.estimate')
        },
        {
          key: ROUTE.ADMIN_MENU,
          icon: React.createElement(IconAdminMenu),
          label: t('sidebar.menu.admin_menu'),
          children: [
            {
              key: ROUTE.EMPLOYEE,
              label: t('sidebar.menu.employee')
            },
            {
              key: ROUTE.VENDOR,
              label: t('sidebar.menu.vendor')
            },
            {
              key: ROUTE.ACCOUNT,
              label: t('sidebar.menu.vendor_user')
            },
            {
              key: ROUTE.PRODUCT,
              label: t('sidebar.menu.product_name')
            },
            {
              key: ROUTE.MATERIAL,
              label: t('sidebar.menu.material')
            }
          ]
        },
        {
          key: ROUTE.SETTING,
          icon: React.createElement(IconSetting),
          label: t('sidebar.menu.setting'),
          children: [
            {
              key: ROUTE.NEWS,
              label: t('sidebar.menu.news')
            }
          ]
        }
      ]
    : [
        {
          key: ROUTE.PROJECTS,
          icon: React.createElement(IconFolderEdit),
          label: t('sidebar.menu.project')
        },
        {
          key: ROUTE.TECHNICAL_DRAWING,
          icon: React.createElement(IconDrawing),
          label: t('sidebar.menu.technical_drawings')
        },
        {
          key: ROUTE.PURCHASE_ORDER,
          icon: React.createElement(IconLeading),
          label: t('sidebar.menu.purchase_order')
        }
      ]
}
