import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { ROUTE } from 'config/constants'
import { auth } from 'systems/api'

const AuthRoute = () => {
  const { pathname, search } = useLocation()
  const isLogin = auth.getToken()

  if (isLogin) {
    return <Outlet />
  }

  return (
    <Navigate
      to={{ pathname: ROUTE.LOGIN, search: `redirectUrl=${pathname}${search}` }}
      state={{ from: pathname }}
      replace
    />
  )
}

export default AuthRoute
