import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ROUTE } from 'config/constants'
import DefaultLayout from 'layouts/DefaultLayout'
import AuthRoute from './AuthRoute'
import lazy from './lazy'
import UnAuthRoute from './UnAuthRoute'

export const routes = createBrowserRouter([
  {
    element: <AuthRoute />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            path: ROUTE.HOME,
            element: lazy(() => import('pages/Projects/Projects'))
          },
          {
            path: ROUTE.PROJECTS,
            children: [
              {
                index: true,
                element: lazy(() => import('pages/Projects/Projects'))
              },
              {
                path: ROUTE.PROJECT_DETAIL,
                element: lazy(() => import('pages/ProjectDetail/ProjectDetail'))
              },
              {
                path: ROUTE.ESTIMATE_DETAIL,
                element: lazy(() => import('pages/EstimateDetail/EstimateDetail'))
              },
              {
                path: ROUTE.ESTIMATE_DETAIL_APPROVE,
                element: lazy(() => import('pages/EstimateApprove/EstimateApprove'))
              },
              {
                path: ROUTE.ORDER_DETAIL,
                element: lazy(() => import('pages/OrderDetail/OrderDetail'))
              },
              {
                path: ROUTE.BUDGET_DETAIL,
                element: lazy(() => import('pages/BudgetDetail/BudgetDetail'))
              },
              {
                path: ROUTE.ACTUAL_COST_DETAIL,
                element: lazy(() => import('pages/ActualCostDetail/ActualCostDetail'))
              },
              {
                path: ROUTE.FINAL_DETAIL,
                element: lazy(() => import('pages/FinalPrjDetail/FinalPrjDetail'))
              },
              {
                path: ROUTE.BILL_DETAIL,
                element: lazy(() => import('pages/BillDetail/BillDetail'))
              }
            ]
          },
          {
            path: ROUTE.ESTIMATE,
            children: [
              {
                path: ROUTE.ESTIMATE,
                element: lazy(() => import('pages/Estimates/Estimates'))
              }
            ]
          },
          {
            path: ROUTE.PURCHASE_ORDER,
            children: [
              {
                path: ROUTE.PURCHASE_ORDER,
                element: lazy(() => import('pages/OrderList/OrderList'))
              }
            ]
          },
          {
            path: ROUTE.TECHNICAL_DRAWING,
            children: [
              {
                path: ROUTE.TECHNICAL_DRAWING,
                element: lazy(() => import('pages/TechnicalDrawings/TechnicalDrawings'))
              }
            ]
          },
          {
            path: ROUTE.ACCOUNT,
            children: [
              {
                path: ROUTE.ACCOUNT,
                element: lazy(() => import('pages/Account/Account'))
              }
            ]
          },
          {
            path: ROUTE.EMPLOYEE,
            children: [
              {
                path: ROUTE.EMPLOYEE,
                element: lazy(() => import('pages/Employees/Employees'))
              }
            ]
          },
          {
            path: ROUTE.VENDOR,
            children: [
              {
                path: ROUTE.VENDOR,
                element: lazy(() => import('pages/Contractors/Contractors'))
              }
            ]
          },
          {
            path: ROUTE.PRODUCT,
            children: [
              {
                path: ROUTE.PRODUCT,
                element: lazy(() => import('pages/Products/Products'))
              }
            ]
          },
          {
            path: ROUTE.MATERIAL,
            children: [
              {
                path: ROUTE.MATERIAL,
                element: lazy(() => import('pages/Materials/Materials'))
              }
            ]
          },
          {
            path: ROUTE.SETTING,
            children: [
              {
                index: true,
                element: lazy(() => import('pages/Setting/Setting'))
              },
              {
                path: ROUTE.NEWS,
                element: lazy(() => import('pages/News/News'))
              }
            ]
          }
        ]
      }
    ]
  },
  {
    element: <UnAuthRoute />,
    children: [
      {
        path: ROUTE.LOGIN,
        element: lazy(() => import('pages/Login/Login'))
      },
      {
        path: ROUTE.RESET_PASSWORD,
        element: lazy(() => import('pages/ResetPassword/ResetPassword'))
      },
      {
        path: ROUTE.RECOVER_PASSWORD,
        element: lazy(() => import('pages/RecoverPassword/RecoverPassword'))
      }
    ]
  },
  {
    path: ROUTE.NOT_FOUND,
    element: lazy(() => import('pages/NotFound/NotFound'))
  }
])

const AppRouter = () => {
  return <RouterProvider router={routes} />
}

export default AppRouter
