import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { LogoutOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import ImgLogo from 'assets/images/logo.png'
import ModalConfirm from 'components/Modal/ModalConfirm'
import { ROUTE } from 'config/constants'
import { getSideBarMenu } from 'config/sidebar'
import { logout } from 'services/auth'

const { Sider } = Layout

const DefaultLayout: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [activeMenu, setActiveMenu] = useState<string[] | undefined>()
  const [openKeys, setOpenKeys] = useState<string[] | undefined>()
  const [collapsed, setCollapsed] = useState(false)
  const [openModal, setOpenModal] = useState<'logout' | ''>('')

  const userRole = localStorage.getItem('role')
  const { pathname } = location
  const onCloseModal = useCallback(() => setOpenModal(''), [])

  const onSelectMenu = useCallback(
    ({ key }) => {
      setActiveMenu([key])
      navigate(key)
    },
    [navigate]
  )

  const onOpenChange = (keys) => {
    if (keys.length === 0 || keys.length === 1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys([keys[keys.length - 1]])
    }
  }

  const handleLogout = useCallback(() => {
    onCloseModal()
    logout().then(() => navigate(ROUTE.LOGIN))
  }, [navigate, onCloseModal])

  useEffect(() => {
    setOpenKeys([`/${location.pathname.split('/')[1]}`])
    const selectedKeys = pathname.startsWith(ROUTE.PROJECTS)
      ? [ROUTE.PROJECTS]
      : [location.pathname]
    setActiveMenu(selectedKeys)
  }, [location.pathname])

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        className="bg-primary"
        width={240}
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="h-screen overflow-hidden flex flex-col justify-between">
          <Link
            to={ROUTE.PROJECTS}
            className="h-16 flex justify-center items-center text-white text-[16px] font-bold hidden lg:flex"
          >
            <img
              src={ImgLogo}
              className="w-[50px] h-[48px] mr-1"
              alt="avatar"
            />
            大黒工業システム
          </Link>
          <Menu
            className="flex-1 overflow-y-auto"
            mode="inline"
            items={getSideBarMenu(userRole)}
            onSelect={onSelectMenu}
            selectedKeys={activeMenu}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            onClick={(e) => {
              if (e?.key === ROUTE.PROJECTS) {
                navigate(ROUTE.PROJECTS)
              }
            }}
          />
          <div className="h-[1px] mx-3 mt-4 bg-slate-400" />
          <div
            className="flex flex-row p-4 cursor-pointer gap-4"
            onClick={() => setOpenModal('logout')}
          >
            <LogoutOutlined className="text-white ml-4" />
            <label className="text-white cursor-pointer hidden lg:flex">ログアウト</label>
          </div>
        </div>
      </Sider>
      <Outlet />
      <ModalConfirm
        title={t('sidebar.logout.title')}
        open={openModal === 'logout'}
        onOk={handleLogout}
        onCancel={onCloseModal}
        okText={t('sidebar.logout.ok')}
        cancelText={t('sidebar.logout.cancel')}
      />
    </Layout>
  )
}

export default DefaultLayout
