const IconDrawing = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className="anticon"
    >
      <path
        d="M2.24998 12.938V15.7505H5.06248L13.3575 7.44797L10.545 4.63547L2.24998 12.938ZM16.9575 13.7705L13.77 16.958L9.86998 13.058L11.1975 11.7305L11.9475 12.4805L13.8 10.6205L14.865 11.6855L13.77 12.7505L14.565 13.5005L15.63 12.4505L16.9575 13.7705ZM4.95748 8.12297L1.04248 4.23047L4.22998 1.04297L5.54998 2.37047L3.69748 4.23047L4.49998 5.02547L6.34498 3.16547L7.40998 4.23047L6.34498 5.28797L7.09498 6.03797L4.95748 8.12297ZM15.5325 5.25047C15.825 4.95797 15.825 4.50047 15.5325 4.19297L13.7775 2.46797C13.5 2.17547 13.0125 2.17547 12.72 2.46797L11.34 3.84047L14.1525 6.65297L15.5325 5.25047Z"
        fill="white"
      />
    </svg>
  )
}

export default IconDrawing
