import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import en from './en.json'
import ja from './ja.json'

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    },
    ja: {
      translation: ja
    }
  },
  lng: 'ja',
  fallbackNS: 'ja'
})

export default i18next
