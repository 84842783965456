import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider } from 'antd'
import jaJP from 'antd/locale/ja_JP'
import 'systems/locales/i18n'
import 'dayjs/locale/ja'
import AppRouter from 'router/AppRouter'
import 'assets/styles/main.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#215493',
          borderRadius: 3
        }
      }}
      locale={jaJP}
      button={{
        className: 'shadow-none'
      }}
    >
      <AppRouter />
    </ConfigProvider>
  </React.StrictMode>
)
