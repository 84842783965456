const IconSetting = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      className="anticon"
    >
      <g clipPath="url(#clip0_505_6661)">
        <mask
          id="mask0_505_6661"
          //   style='mask-type:luminance'
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18"
        >
          <path
            d="M6.8565 16.3545C5.61602 15.9842 4.49257 15.2989 3.5955 14.3655C3.88413 14.0232 4.06405 13.6025 4.11225 13.1574C4.16044 12.7122 4.07472 12.2628 3.86604 11.8666C3.65737 11.4704 3.33523 11.1455 2.94086 10.9335C2.54649 10.7214 2.09781 10.6319 1.65225 10.6763C1.55074 10.1791 1.49974 9.67287 1.5 9.1654C1.5 8.38165 1.62 7.62565 1.8435 6.9154H1.875C2.25741 6.91552 2.63354 6.81818 2.96788 6.63255C3.30221 6.44693 3.58372 6.17916 3.78583 5.85452C3.98793 5.52988 4.10395 5.15909 4.12295 4.77715C4.14194 4.39521 4.06327 4.01474 3.89438 3.67165C4.7736 2.85296 5.8369 2.25783 6.9945 1.93652C7.18289 2.30642 7.46994 2.617 7.82389 2.83388C8.17784 3.05076 8.58489 3.1655 9 3.1654C9.41511 3.1655 9.82216 3.05076 10.1761 2.83388C10.5301 2.617 10.8171 2.30642 11.0055 1.93652C12.1631 2.25783 13.2264 2.85296 14.1056 3.67165C13.9355 4.01714 13.8569 4.40052 13.8773 4.78508C13.8978 5.16965 14.0166 5.54253 14.2224 5.86803C14.4282 6.19354 14.7141 6.46077 15.0527 6.64416C15.3914 6.82754 15.7714 6.92093 16.1565 6.9154C16.3849 7.64354 16.5007 8.40229 16.5 9.1654C16.5 9.6829 16.4475 10.1884 16.3478 10.6766C15.9022 10.6323 15.4535 10.7218 15.0591 10.9339C14.6648 11.1459 14.3426 11.4708 14.134 11.867C13.9253 12.2632 13.8396 12.7126 13.8878 13.1577C13.936 13.6029 14.1159 14.0236 14.4045 14.3659C13.5074 15.2992 12.3839 15.9843 11.1435 16.3545C10.9978 15.9005 10.7118 15.5045 10.3265 15.2235C9.94131 14.9425 9.47681 14.7911 9 14.7911C8.52319 14.7911 8.05869 14.9425 7.67346 15.2235C7.28823 15.5045 7.00217 15.9005 6.8565 16.3545Z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth="3"
            strokeLinejoin="round"
          />
          <path
            d="M9 11.79C9.34472 11.79 9.68606 11.7221 10.0045 11.5902C10.323 11.4583 10.6124 11.2649 10.8562 11.0212C11.0999 10.7774 11.2933 10.4881 11.4252 10.1696C11.5571 9.8511 11.625 9.50976 11.625 9.16504C11.625 8.82032 11.5571 8.47897 11.4252 8.1605C11.2933 7.84202 11.0999 7.55264 10.8562 7.30888C10.6124 7.06513 10.323 6.87177 10.0045 6.73986C9.68606 6.60794 9.34472 6.54004 9 6.54004C8.30381 6.54004 7.63613 6.8166 7.14384 7.30888C6.65156 7.80117 6.375 8.46885 6.375 9.16504C6.375 9.86123 6.65156 10.5289 7.14384 11.0212C7.63613 11.5135 8.30381 11.79 9 11.79Z"
            fill="black"
            stroke="black"
            strokeWidth="3"
            strokeLinejoin="round"
          />
        </mask>
        <g mask="url(#mask0_505_6661)">
          <path
            d="M0 0.165039H18V18.165H0V0.165039Z"
            fill="currentcolor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_505_6661">
          <rect
            width="18"
            height="18"
            fill="currentcolor"
            transform="translate(0 0.165039)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSetting
