import React from 'react'
import { Spin } from 'antd'

const lazy = (importFunc: () => Promise<{ default: React.ComponentType<any> }>) => {
  const Component = React.lazy(importFunc)

  return (
    <React.Suspense
      fallback={
        <div className="h-screen w-full flex justify-center items-center">
          <Spin />
        </div>
      }
    >
      <Component />
    </React.Suspense>
  )
}

export default lazy
