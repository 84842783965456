import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { ROUTE } from 'config/constants'
import { auth } from 'systems/api'

const UnAuthRoute = () => {
  const isLogin = auth.getToken()

  if (!isLogin) {
    return <Outlet />
  }

  return <Navigate to={ROUTE.HOME} />
}

export default React.memo(UnAuthRoute)
