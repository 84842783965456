const IconAdminMenu = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="anticon"
    >
      <path
        d="M16.25 11.25H3.75C3.40482 11.25 3.125 11.5298 3.125 11.875V15.625C3.125 15.9702 3.40482 16.25 3.75 16.25H16.25C16.5952 16.25 16.875 15.9702 16.875 15.625V11.875C16.875 11.5298 16.5952 11.25 16.25 11.25Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 3.75H3.75C3.40482 3.75 3.125 4.02982 3.125 4.375V8.125C3.125 8.47018 3.40482 8.75 3.75 8.75H16.25C16.5952 8.75 16.875 8.47018 16.875 8.125V4.375C16.875 4.02982 16.5952 3.75 16.25 3.75Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 6.25C14.25 6.35355 14.1661 6.4375 14.0625 6.4375C13.9589 6.4375 13.875 6.35355 13.875 6.25C13.875 6.14645 13.9589 6.0625 14.0625 6.0625C14.1661 6.0625 14.25 6.14645 14.25 6.25Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M14.0625 14.6875C14.5803 14.6875 15 14.2678 15 13.75C15 13.2322 14.5803 12.8125 14.0625 12.8125C13.5447 12.8125 13.125 13.2322 13.125 13.75C13.125 14.2678 13.5447 14.6875 14.0625 14.6875Z"
        fill="white"
      />
    </svg>
  )
}

export default IconAdminMenu
