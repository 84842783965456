import axios, { AxiosError, AxiosResponse } from 'axios'
import { API_BASE_URL, ROUTE } from 'config/constants'
import ApiAuth from './ApiAuth'
import SingleRequestHandler from './SingleRequest'

const makeAxiosInstance = (baseURL: string, auth: ApiAuth | unknown) => {
  const singleRequestHandler = new SingleRequestHandler()

  const axiosInstance = axios.create({ baseURL })

  axiosInstance.interceptors.request.use(
    (config) => {
      config.params = config.params || {}

      if (auth instanceof ApiAuth) {
        config.headers['Access-Control-Allow-Headers'] = 'Authorization'
        config.headers.Authorization = `Bearer ${auth.getToken()}`
      }

      // Handle single request
      const cancelToken = axios.CancelToken.source()
      config.cancelToken = cancelToken.token

      if (config.singleRequest) {
        singleRequestHandler.handle(`${config.method}::${config.url}`, cancelToken.cancel)
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response.data
    },
    (error: AxiosError | any) => {
      if (error.response?.data?.error_code === 401) {
        if (auth instanceof ApiAuth) {
          auth.removeToken()
          if (window && window.location) {
            window.location.href = '/login'
          }
        }
        return Promise.reject(error)
      }

      if (error.response?.status !== 401 && !axios.isCancel(error)) {
        return Promise.reject(error)
      }
      switch (Number(error.response?.status)) {
        case 401:
          if (auth instanceof ApiAuth) {
            auth.removeToken()
          }
          window.location.href = ROUTE.LOGIN
          return Promise.reject(error)
        default:
          return Promise.reject(error)
      }
    }
  )

  return axiosInstance
}

export const auth = new ApiAuth()

export const api = makeAxiosInstance(API_BASE_URL, auth)
