const IconLeading = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="anticon"
    >
      <path
        d="M15.625 13.75V5.00002C15.626 4.7535 15.5782 4.50922 15.4844 4.28126C15.3905 4.05331 15.2524 3.8462 15.0781 3.67189C14.9038 3.49757 14.6967 3.3595 14.4688 3.26564C14.2408 3.17178 13.9965 3.12398 13.75 3.12502H3.125"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 8.125H13.125"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 10.625H13.125"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.72656 6.25095C1.52417 6.02434 1.37988 5.75195 1.30611 5.45721C1.23234 5.16247 1.2313 4.85421 1.30308 4.55898C1.37486 4.26375 1.51731 3.99039 1.71817 3.76242C1.91903 3.53445 2.17228 3.35871 2.45612 3.25032C2.73996 3.14193 3.04589 3.10414 3.34757 3.14021C3.64926 3.17628 3.93766 3.28512 4.18795 3.45736C4.43824 3.6296 4.64292 3.86009 4.78438 4.12899C4.92584 4.39788 4.99984 4.69712 5 5.00095V15.001C5.00016 15.3048 5.07416 15.604 5.21562 15.8729C5.35708 16.1418 5.56176 16.3723 5.81205 16.5445C6.06234 16.7168 6.35074 16.8256 6.65243 16.8617C6.95411 16.8978 7.26004 16.86 7.54388 16.7516C7.82772 16.6432 8.08097 16.4675 8.28183 16.2395C8.48269 16.0115 8.62514 15.7382 8.69692 15.4429C8.7687 15.1477 8.76766 14.8394 8.69389 14.5447C8.62012 14.25 8.47583 13.9776 8.27344 13.751H17.0234C17.2648 14.0208 17.4229 14.3547 17.4786 14.7125C17.5343 15.0702 17.4852 15.4364 17.3373 15.7668C17.1895 16.0973 16.9491 16.3779 16.6452 16.5747C16.3414 16.7715 15.987 16.8761 15.625 16.876H6.875"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconLeading
